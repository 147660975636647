@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "../Modal/Modal.module.scss";

.alertContainer {
  @extend .modalContainer;

  .content {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;

    padding: 0 1.25rem 1.25rem !important;

    @include tablet {
      padding: 0 1rem !important;
    }

    @include mobile {
      padding: 0 0.25rem 0.25rem !important;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    padding: 0 1.25rem 1.25rem !important;

    @include tablet {
      padding: 0 1rem !important;
    }

    @include mobile {
      padding: 0 0.25rem 0.25rem !important;
    }
    button {
      margin-left: 1rem;
    }
  }
}
