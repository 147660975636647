@import 'colors';
@import 'responsive';

@mixin Roboto {
  font-family: 'Roboto', sans-serif;
}

@mixin Rubik {
  font-family: 'Rubik', sans-serif;
}

@mixin bold {
  font-weight: bold;
}

@mixin marginLeftRight10px {
  margin-left: 10px;
  margin-right: 10px;
}

@mixin baseContainer {
  display: grid;
  gap: 2.25rem;
  height: min-content;
}

@mixin baseModalContainer {
  display: grid;
  gap: 1.5rem;
  height: min-content;
}

@mixin baseTitle {
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0;
  color: $black-2;
  text-transform: uppercase;
}

@mixin baseFormContainer {
  display: grid;
  gap: 1rem;
  height: min-content;
}

@mixin statusCardBody {
  display: grid;
  height: min-content;
  @include desktop {
    grid-template-columns: 2fr 1fr;
  }

  .right {
    row-gap: 0.75rem;
    justify-content: flex-end;
  }

  .left {
    align-items: center;
    row-gap: 0.75rem;
    height: auto;
    font-weight: bold;
  }
}

@mixin card {
  width: 100%;
  background: $white;
  box-shadow: 0 3px 10px $shadow-grey-2;
  border-radius: 10px;
  padding: 1rem 1.25rem 1.25rem;
  @include desktop {
    padding: 1.625rem 2.25rem 2.25rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @include tablet {
    max-width: calc(100vw - 2rem);
    overflow: auto;
  }

  @include mobile {
    padding: 1rem 0.25rem 0.25rem;
  }

  .titleContainer {
    @include baseContainer;
    padding-bottom: 1rem;
    align-items: center;

    &.withBorderBottom {
      border-bottom: 1px solid $light-grey;
      margin-bottom: 1rem;
    }

    .title {
      @include baseTitle;
    }
  }
}

@mixin buttonType($color, $borderColor, $backgroundColor, $hoverColor, $hoverBorderColor, $hoverBackgroundColor) {
  border: 1px solid $borderColor;
  background: $backgroundColor;
  color: $color;
  transition: all 0.3s ease-out;

  &:hover {
    border: 1px solid $hoverBorderColor;
    background: $hoverBackgroundColor;
    color: $hoverColor;
  }

  &.textOnly {
    background: transparent;
    border: none;
  }
}

@mixin button {
  padding: 0.8125rem 1.375rem;
  border-radius: 10px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1875rem;
  cursor: pointer;
  height: min-content;
  text-wrap: nowrap;

  @include tablet {
    padding: 14px 16px;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.icon {
    padding: 0.75rem;
  }

  &.small {
    padding: 0.625rem 1.0625rem;
    font-size: 0.8125rem;
    line-height: 0.9375rem;

    &.icon {
      padding: 0.625rem;
    }
  }

  &.smaller {
    padding: 0.325rem 0.5625rem;
    font-size: 0.8125rem;
    line-height: 0.9375rem;

    &.icon {
      padding: 0.625rem;
    }
  }

  &.tiny {
    padding: 0.325rem 0.5625rem;
    font-size: 0.8125rem;
    line-height: 0.9375rem;

    &.icon {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      line-height: 1;
      padding: 0;
    }
  }

  &.primary {
    &.simple {
      @include buttonType($light-grey-2, $blue, $blue, $light-grey-2, $blue-2, $blue-2);
    }

    &.reverted {
      @include buttonType($blue, $mid-grey, $mid-grey, $blue, $mid-grey-2, $mid-grey-2);
    }

    &.outlined {
      @include buttonType($blue, $blue, transparent, $light-grey-2, $blue, $blue);
    }
  }

  &.warning {
    &.simple {
      @include buttonType($light-grey-2, $orange, $orange, $light-grey-2, $orange-2, $orange-2);
    }

    &.reverted {
      @include buttonType($orange, $pastel-pink, $pastel-pink, $orange, $pastel-pink-2, $pastel-pink-2);
    }

    &.outlined {
      @include buttonType($orange, $orange, transparent, $light-grey-2, $orange, $orange);
    }
  }

  &.inactive {
    cursor: not-allowed;

    &.simple {
      @include buttonType($light-grey-2, $grey, $grey, $light-grey-2, $grey-2, $grey-2);
    }

    &.reverted {
      @include buttonType($grey, $grey-3, $grey-3, $grey, $grey-4, $grey-4);
    }

    &.outlined {
      @include buttonType($grey, $grey, transparent, $light-grey-2, $grey, $grey);
    }
  }
  //($color, $borderColor, $backgroundColor, $hoverColor, $hoverBorderColor, $hoverBackgroundColor) {
  &.grey {
    &.simple {
      @include buttonType($black-2, $light-grey-3, $light-grey-3, $black-2, $light-grey-2, $light-grey-2);
    }

    &.reverted {
      @include buttonType($light-grey-3, $black-2, $black-2, $black-2, $light-grey-3, $light-grey-3);
    }

    &.outlined {
      @include buttonType($black-2, $light-grey-3, transparent, $black-2, $light-grey-2, $light-grey-2);
    }
  }

  &.success {
    &.simple {
      @include buttonType($light-grey-2, $green, $green, $light-grey-2, $green-2, $green-2);
    }

    &.reverted {
      @include buttonType($green, $light-green, $light-green, $green, $light-green-2, $light-green-2);
    }

    &.outlined {
      @include buttonType($green, $green, transparent, $light-grey-2, $green, $green);
    }
  }

  &.danger {
    &.simple {
      @include buttonType($light-grey-2, $red, $red, $light-grey-2, $red-2, $red-2);
    }

    &.reverted {
      @include buttonType($red, $pink, $pink, $red, $pink-2, $pink-2);
    }

    &.outlined {
      @include buttonType($red, $red, transparent, $light-grey-2, $red, $red);
    }
  }

  &.notify {
    &.simple {
      @include buttonType($light-yellow, $yellow, $yellow, $light-yellow, $light-yellow-2, $light-yellow-2);
    }

    &.reverted {
      @include buttonType($yellow, $light-yellow, $light-yellow, $yellow, $light-yellow-2, $light-yellow-2);
    }

    &.outlined {
      @include buttonType($yellow, $yellow, transparent, $light-yellow, $yellow, $yellow);
    }
  }

  &.eyes {
    &.simple {
      @include buttonType($pastel-pink, $orange, $orange, $pastel-pink, $pastel-pink-2, $pastel-pink-2);
    }

    &.reverted {
      @include buttonType($orange, $pastel-pink, $pastel-pink, $orange, $pastel-pink-2, $pastel-pink-2);
    }

    &.outlined {
      @include buttonType($orange, $orange, transparent, $pastel-pink, $orange, $orange);
    }
  }
}

@mixin draggableTable {
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
    min-width: 770px;

    @include tablet {
      min-width: unset;
    }

    thead {
      padding-bottom: 1rem;

      tr {
        background-color: $white;

        th {
          border-bottom: 1px solid $light-grey;
          padding-bottom: 1rem;
        }
      }
    }

    td {
      font-size: 1rem;
      line-height: 1.188rem;
      font-weight: 300;
      letter-spacing: 0;
      color: $black-2;
      height: 48px;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      @include mobile {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }

    th {
      text-align: left;
      font-size: 1rem;
      line-height: 1.188rem;
      font-weight: bold;
      letter-spacing: 0;
      color: $blue;
    }

    .columnActive {
      width: 50px;
    }

    .columnSkuId {
      width: 120px;
    }

    .columnName {
    }

    .columnDescription {
    }

    .columnPrice {
      width: 170px;
    }

    .actions {
      justify-content: flex-end;
    }
  }
}

@mixin settingTab {
  @include baseContainer;

  @include desktop {
    grid-template-columns: 1fr 3fr;
  }

  .bodyLeft {
    @include baseContainer;

    row-gap: 2.25rem;

    .fontSize20 {
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .bodyRight {
    @include baseContainer;

    .cardContainer {
      @include baseContainer;

      row-gap: 1.25rem;

      .itemsContainer2 {
        display: grid;
        gap: 1.25rem;
        height: min-content;
        align-items: baseline;
        @include desktop {
          grid-template-columns: 1fr 1fr;
        }
      }

      .itemsContainer3 {
        display: grid;
        gap: 1.25rem;
        height: min-content;
        align-items: baseline;
        @include desktop {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }

  .select {
    display: flex;
    width: 100%;
  }
}

@mixin tabCard {
  padding: 0.8rem;
  @include tablet {
    display: grid;
    grid-template-columns: 1fr;

    button {
      margin: 0;
      width: 100%;
    }
  }
}
