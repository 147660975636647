@import 'libs/common/styles/colors';
@import 'libs/common/styles/general';

@mixin inputState($color, $backgroundColor) {
	background: $backgroundColor;
	color: $color;
}

.withoutBorder {
	border: 0 !important;

	.suffix {
		border: 0 !important;
	}
}

.input {
	.outerContainer {
		width: 100%;
	}

	.messagePlaceholder {
		height: 0.9375rem !important;
	}

	&:not(.checkbox, .select) {
		display: grid;
		grid-template-columns: 1fr 3fr;
		justify-content: space-between;
		align-items: center;

		&.noLabel {
			grid-template-columns: 1fr;
		}

		&.vertical {
			grid-template-columns: auto;
			gap: 0.5rem;
			justify-content: unset;
			align-items: end;
			align-content: baseline;
		}

		&.horizontal {
			gap: 0.5rem;
			justify-content: unset;
			align-items: center;
			@include desktop {
				grid-template-columns: 1fr 1fr;
			}
		}

		label {
			@include Rubik;
			text-align: left;
			font-weight: normal;
			line-height: 1.1875rem;
			font-size: 1rem;
			letter-spacing: 0;
			color: $black-2;

			&.require {
				color: $red;
			}
		}

		.outerContainer {
			display: grid;
			gap: 5px;
		}

		.inputContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.8125rem 0.9375rem;
			background: $white;
			border: 1px solid $blue;
			border-radius: 10px;
			height: 2.813rem;

			&.textarea {
				height: auto;
				min-height: 2.813rem;
			}

      &.richText {
        height: max-content;
        padding: 0;
        border: none;
        background: transparent;

        .richTextEditor {
          width: 100%;
        }

        :global {
          .jodit-react-container {
            width: 100%;
          }
        }
      }

			input,
			select,
			textarea {
				@include Rubik;
				width: 100%;
				border: none;
				outline: none;
				background: $white;
				font-weight: 300;
				line-height: 1.1875rem;
				font-size: 1rem;
				letter-spacing: 0;
				color: $black-2;

				::placeholder {
					font-weight: 300;
					line-height: 1.1875rem;
					font-size: 1rem;
					letter-spacing: 0;
					color: $grey;
				}

				&[disabled] {
					background-color: $grey-3;
				}
			}

			textarea {
				resize: none;
				display: block;
				width: 100%;
				overflow: hidden;

				&::placeholder {
				}

				&:focus-visible {
					outline: none;
				}
			}

			.selectArrow {
				display: none;
			}

			select {
				height: calc(100% + (2 * 0.8125rem));
				width: calc(100% + (2 * 0.9375rem));
				margin-left: -0.9375rem;
				padding-left: 0.9375rem;
				border-radius: 10px;
				-moz-appearance: none; /* Firefox */
				-webkit-appearance: none; /* Safari and Chrome */
				appearance: none;

				+ .selectArrow {
					display: block;
					transition: all ease-in-out 0.3s;
				}

				&:focus,
				&:focus-within {
					+ .selectArrow {
						transform: rotate(180deg);
					}
				}
			}

			.icon {
				color: $blue;
			}

			.prefix,
			.suffix {
				display: grid;
				place-items: center;
				background: $grey-3;
				border-color: $blue;
				width: max-content;
				white-space: nowrap;
				padding-left: 10px;
				padding-right: 10px;
				height: calc(100% + (2 * 0.8125rem));
				font-size: 1rem;
				line-height: 1.188rem;
				font-weight: 300;
				letter-spacing: 0;
				color: $black-2;
				&.tel {
					padding: 0;
				}
			}

			.prefix {
				margin-left: -0.9375rem;
				margin-right: 0.9375rem;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				border-right: 1px solid;
			}

			.suffix {
				margin-left: 0.9375rem;
				margin-right: -0.9375rem;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				border-left: 1px solid;
			}
		}

		&.error {
			.inputContainer {
				border-color: $red;

				.prefix,
				.suffix {
					border-color: $red;
				}
			}

			.message {
				@include Rubik;
				text-align: left;
				font-weight: 300;
				line-height: 0.9375rem;
				font-size: 0.8125rem;
				letter-spacing: 0;
				color: $red;
			}

			.icon {
				color: $red;
			}
		}

		&.success {
			.inputContainer {
				border-color: $green;
			}

			.icon {
				color: $green;
			}
		}

		&.disabled {
			cursor: not-allowed;

			* {
				cursor: not-allowed;
			}

			.inputContainer {
				filter: grayscale(0.5);
				background-color: $grey-3;
			}
		}
	}

	.passwordToggle {
		cursor: pointer;
		margin-right: 0.5rem;

		.show {
		}

		.hide {
			display: none;
		}

		&.active {
			.show {
				display: none;
			}

			.hide {
				display: block;
			}
		}
	}

}

.checkbox,
.radio {
	display: flex;
	align-items: center;
	font-size: 1rem;
	line-height: 1.1875rem;
	font-weight: 300;
	letter-spacing: 0;
	color: $black-2;
	cursor: pointer;

	input {
		margin-right: 10px;
		display: grid;
		place-items: center;
		appearance: none;
		height: 1.25rem;
		width: 1.25rem;
		border: 1px solid $blue;
		background: $white;

		&::after {
			cursor: pointer;
		}

		&[value='true'] {
			background: $blue;

			&::after {
				cursor: pointer;
				content: ' ';
				background: url('/icons/check-grey.svg') no-repeat center;
				width: 0.625rem;
				height: 100%;
			}
		}

		&[disabled] {
			background: $grey-3;
			border-color: $grey;
			cursor: not-allowed;

			&::after {
				cursor: not-allowed;
			}

			&[value='true'] {
				border-color: $blue-4;
				background: $blue-4;
			}
		}
	}

	&.disabled {
		cursor: not-allowed;

		input {
			cursor: not-allowed;
		}
	}
}

.radio {
	input {
		border-radius: 50%;
		background: $white;
		border-width: 1px;

		&:not([value='']) {
		}

		&:checked {
			border: 5px solid $blue;
		}
	}
}

.select {
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr;
	align-items: center;

	.outerContainer {
		display: grid;
		gap: 5px;
	}

	&.vertical {
    display: grid;
		grid-template-columns: auto;
		gap: 0.5rem;
		justify-content: unset;
		align-items: end;
	}

	&.error {
		div > div,
		div > div:hover,
		div > div:focus,
		.select > div {
			border-color: $red !important;
		}

		.message {
			@include Rubik;
			text-align: left;
			font-weight: 300;
			line-height: 0.9375rem;
			font-size: 0.8125rem;
			letter-spacing: 0;
			color: $red;
		}
	}

	&.success {
		div > div,
		div > div:hover,
		div > div:focus {
			border-color: $green !important;
		}
	}
}

.small {
	&.input {
		&:not(.checkbox, .select) {
			.inputContainer {
				height: 1.875rem;

				&.textarea {
					height: auto;
					min-height: 1.875rem;
				}

				select {
					height: calc(100% + (2 * 0.8125rem));
				}

				.prefix,
				.suffix {
					height: calc(100% + (2 * 0.8125rem));
				}
			}
		}
	}
}

.medium {
}

.large {
}

.label {
  &.hasInfo {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 0.5rem;

  }
}
